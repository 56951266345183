import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-float-label"
}
const _hoisted_2 = {
  key: 1,
  class: "p-float-label"
}
const _hoisted_3 = {
  key: 2,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    (
        _ctx.answerKey == 'curp' ||
          (_ctx.answerKey == 'city' || _ctx.answerKey == 'state')
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_InputText, {
            onChange: _ctx.setValues,
            modelValue: _ctx.v$.name.$model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.name.$model) = $event)),
            placeholder: _ctx.placeholder,
            class: _normalizeClass({ 'p-invalid': _ctx.v$.name.$invalid && _ctx.isSubmitted }),
            readonly: ""
          }, null, 8, ["onChange", "modelValue", "placeholder", "class"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_InputText, {
            onChange: _ctx.setValues,
            modelValue: _ctx.v$.name.$model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.name.$model) = $event)),
            placeholder: _ctx.answerKey == 'income' ? 'Ingreso Mensual' : _ctx.placeholder,
            class: _normalizeClass({ 'p-invalid': _ctx.v$.name.$invalid && _ctx.isSubmitted })
          }, null, 8, ["onChange", "modelValue", "placeholder", "class"])
        ])),
    ((_ctx.v$.name.$invalid && _ctx.isSubmitted) || _ctx.v$.name.$pending.$response)
      ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.validationLabel), 1))
      : _createCommentVNode("", true)
  ]))
}