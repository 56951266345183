
import { defineComponent, reactive } from "vue";
import InputMask from "primevue/inputmask";
import { required, numeric, minLength, minValue, maxValue } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "TypePhone",
  components: {
    InputMask,
  },
  props: {
    label: String,
    validationLabel: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    answerKey: String,
    minLength: Number,
  },
  setup(props) {
    const f = reactive({
      name: "",
    });

    var rules;
    var placeholder = `${props.minLength} dígitos`;
      let min = props.minLength || 10;
      let mask = "99999";
      let minVal = "1";
      rules = props.isRequired
        ? (rules = {
            name: {
              numeric,
              minLength: minLength(min),
              minValue: minValue(minVal),
              required,
            },
          })
        : (rules = {
            name: {
              numeric,
              minLength: minLength(min),
              minValue: minValue(minVal),
            },
          });

      const v$ = useVuelidate(rules, f);
      return { f, v$, placeholder, mask };
  },
  mounted() {
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      typeof step === "object" &&
      this.answerKey &&
      Object.prototype.hasOwnProperty.call(step, this.answerKey)
    )
      this.v$.name.$model = step[this.answerKey];
    this.setValues();
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model || undefined,
        valid:
          !(!this.v$.name.$model && this.isRequired) && !this.v$.name.$invalid,
        answerKey: this.answerKey,
      });
    },
  },
});
