export function find() {
  return {
    code: "OK",
    message: "La operación se completó exitosamente.",
    data: {
      id: 1,
      name: "IslaEducativa",
      order: 0,
      active: true,
      steps: [
        {
          id: 1,
          name: "Datos de contacto",
          details: "Ingresa la siguiente información, acepta los términos y haz clic en Siguiente",
          order: 1,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 1,
              name: "Nombre completo",
              key: "name",
              type: "string",
              component: "text",
            },
          ],
          filters: [],
        },
        {
          id: 2,
          name: "",
          details: "",
          order: 2,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 4,
              name: "Teléfono celular",
              key: "phone",
              type: "string",
              component: "phone",
            },
          ],
          filters: [],
        },
        {
          id: 3,
          name: "",
          details: "",
          order: 3,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 5,
              name: "Correo electrónico",
              key: "email",
              type: "string",
              component: "email",
            },
          ],
          filters: [],
        },
        {
          id: 4,
          name: "",
          details: "Para continuar, acepta los siguientes términos:",
          order: 4,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 6,
              name: "Acepto los términos y condiciones del servicio",
              key: "termsAndConditions",
              type: "boolean",
              component: "switch",
            },
            {
              id: 7,
              name: "Acepto la política de privacidad",
              key: "privacyNotice",
              type: "boolean",
              component: "switch",
            },
          ],
          filters: [],
        },
        {
          id: 5,
          name: "",
          details: "",
          order: 1,
          step: 2,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 9,
              name: "¿Qué te gustaría estudiar?",
              key: "schoolGradeId",
              type: "string",
              component: "radiobutton",
              catalog: "schoolgrade",
            },
          ],
          filters: [],
        },
        {
          id: 6,
          name: "",
          details: "",
          order: 1,
          step: 3,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 12,
              name: "¿Qué modalidad te interesa?",
              key: "motivationId",
              type: "string",
              component: "radiobutton",
              catalog: "motivation"
            },
          ],
          filters: [],
        }
      ],
    },
  };
}
