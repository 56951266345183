import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "h6" }
const _hoisted_2 = { class: "font-primary" }
const _hoisted_3 = { class: "dropdown" }
const _hoisted_4 = {
  key: 0,
  class: "p-error font-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h4", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createVNode(_component_Dropdown, {
          options: _ctx.catalogData,
          placeholder: "Selecciona una opción",
          onChange: _ctx.setValues,
          modelValue: _ctx.v$.name.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.name.$model) = $event)),
          required: _ctx.isRequired,
          showClear: !_ctx.isRequired,
          optionLabel: "name",
          optionValue: "id",
          loading: _ctx.loading,
          class: _normalizeClass({ 'p-invalid': _ctx.v$.name.$invalid && _ctx.isSubmitted })
        }, null, 8, ["options", "onChange", "modelValue", "required", "showClear", "loading", "class"])
      ]),
      (_ctx.v$.name.$invalid && _ctx.isSubmitted)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.validationLabel), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}