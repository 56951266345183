
import { defineComponent, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";
import _ from "underscore";
import curp from "curp";

export default defineComponent({
  name: "TypeText",
  components: {
    InputText,
  },
  data() {
    return {
      step: sessionStorage.getItem("steps"),
      StateOfBirth: "",
      Gender: "",
    };
  },
  props: {
    label: String,
    validationLabel: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    answerKey: String,
    state: String,
    city: String,
    birthdate: Date,
    stateOfBirth: String,
    gender: String,
  },
  setup(props) {
    var rules;
    var placeholder = `${props.label}`;
    const f = reactive({
      name: "",
    });
    if (props.isRequired) {
      rules = {
        name: { required },
      };
    } else {
      rules = {
        name: {},
      };
    }

    const showMessage = ref(false);
    const date = ref();

    const v$ = useVuelidate(rules, f);

    return { f, v$, showMessage, date, placeholder };
  },
  mounted() {
    let step = JSON.parse(this.step || "{}");
    if (typeof step === "object" && this.answerKey) {
      this.v$.name.$model = step[this.answerKey];
    }
    this.setValues();
  },
  methods: {
    generateCurp() {
      let answers = JSON.parse(this.step || "{}");
      if (
        this.StateOfBirth &&
        this.Gender &&
        this.StateOfBirth != "" &&
        this.Gender != ""
      ) {
        const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
        let stateArray = _.map(Array.from(this.stateOfBirth!.toString()), (letter) => {
          return _.get(acentos, letter) || letter;
        });
        let state = stateArray.join('').toLowerCase().replace(/([A-Z])/g, " $1");
        state = state.split(" ").join("_").toUpperCase();
        if (state == "CIUDAD_DE_MÉXICO") state = "CDMX";

        let persona = curp.getPersona();
        let date = `0${this.birthdate!.getDate()}`.slice(-2);
        let month = `0${this.birthdate!.getMonth() + 1}`.slice(-2);
        persona.nombre = answers["name"];
        persona.apellidoPaterno = answers["paternalSurname"];
        persona.apellidoMaterno = answers["maternalSurname"];
        persona.fechaNacimiento = `${date}-${month}-${this.birthdate!.getFullYear()}`;
        persona.genero =
          this.gender == "Hombre"
            ? curp.GENERO.MASCULINO
            : curp.GENERO.FEMENINO;
        persona.estado = curp.ESTADO[state];
        this.v$.name.$model = curp.generar(persona);
        this.setValues(); 
      }
    },
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model,
        valid: !this.v$.name.$invalid,
        answerKey: this.answerKey,
      });
    },
  },
  watch: {
    state(value) {
      if (this.answerKey == "state") {
        this.v$.name.$model = value;
        this.setValues();
      }
    },
    city(value) {
      if (this.answerKey == "city") {
        this.v$.name.$model = value;
        this.setValues();
      }
    },
    birthdate(value) {
      if (this.answerKey == "curp") {
        this.generateCurp();
      }
    },
    stateOfBirth(value) {
      if (this.answerKey == "curp") {
        this.StateOfBirth = value;
        this.generateCurp();
      }
    },
    gender(value) {
      if (this.answerKey == "curp") {
        this.Gender = value;
        this.generateCurp();
      }
    },
  },
});
